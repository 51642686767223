import * as React from 'react';
import Layout from "../../Components/Layout"
import { FooterComponent } from '../../Components/FooterComponent';
import ProductsIndex from '../../modules/Products/ProductsIndex';
import HeaderComponent from '../../Components/HeaderComponent';
import Seo from '../../Components/Seo';

const index = () => {
  return (
    <>
       <Seo title="Products | Webbermill"/>
      <Layout name="products"  hasProducts={true}>
        <title>Product Page</title>
        <HeaderComponent hasProduct/>
        <ProductsIndex  />
        <FooterComponent />
      </Layout>
    </>
  )
}

export default index