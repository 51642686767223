import * as React from "react";
import { CustomLinkBtn } from "../../Components/CustomButton";
import { ArrowSvg } from "../../Components/SVGs";
import { TRIP_SUITE_HOMEPAGE } from "../../constant/routes";
import { Link } from "gatsby";
import { finance, HumanResources, inventory, sales, transport } from "../../Components/FooterComponent";
import ProductCard from "../Home/components/ProductCard";
import FrontDeskLogo from "../../../public/assets/icons/FrontDeskLogo";

const ProductList = [
  {
    id: 1,
    logo: "https://webbermill-website.s3.amazonaws.com/static/static/products/trip.svg",
    name: "Transport Suite",
    description:
      "Boost your transportation business with 100% control of  planning, collaboration & execution.",
    link: TRIP_SUITE_HOMEPAGE,
  },
  {
    id: 2,
    logo: "https://webbermill-website.s3.amazonaws.com/static/static/products/pcm.svg",
    name: "Project Cost Management",
    description:
      "Budget, execute and monitor your projects performance all on real-time.",
    link: "",
  },
  {
    id: 3,
    logo: "https://webbermill-website.s3.amazonaws.com/static/static/products/reception.svg",
    name: "Visitor Management Suite",
    description:
      "Improve workplace experiences for  employee & visitor sign in's & delivery management.",
    link: "",
  },
  {
    id: 4,
    logo: "https://webbermill-website.s3.amazonaws.com/static/static/products/soon.svg",
    name: "HR Management Suite",
    description:
      "Put your team first with an all automated integrated human resource management system.",
    link: "",
  },
];

const ProductsContent = ({ logo, name, description, link }) => (
  <div className="products__content-container">
    <Link to={link}>
      <div className="products__content-container-items">
        <div className="products-card">
          <div className="products-img">
            <img src={logo} alt="products" width={70} />
          </div>
          <div className="products-name">
            <h5>{name}</h5>
          </div>
          <div className="description">
            <h5>{name}</h5>
            <p>{description}</p>
            <div className="products-link-2">
              {link ? (
                <CustomLinkBtn
                  text="Learn More"
                  url={link}
                  icon={<ArrowSvg />}
                />
              ) : (
                <a>Coming Soon!</a>
              )}
            </div>
          </div>
          <div className="products-link">
            {link ? (
              <CustomLinkBtn text="Learn More" url={link} icon={<ArrowSvg />} />
            ) : (
              <a>Coming Soon!</a>
            )}
          </div>
          <div className="ratings">
            {link && (
              <img
                src="https://webbermill-website.s3.amazonaws.com/static/static/products/rating.svg"
                alt="Rating"
              />
            )}
          </div>
        </div>
      </div>
    </Link>
  </div>
);

const ProductsIndex = () => {
  return (
    <>
      <section className="products__content container">
        <div className="products__content-text">
          <div className="title">
            <h5>
              Apps inspired by <strong>your needs</strong>
            </h5>
          </div>
          <p>
            Choose from an unrivaled set of apps to work more efficiently with
            customizable capabilities that empower your business to move faster.
          </p>
        </div>

        <div className="mb-5">
          <h5 style={{fontFamily: "Poppins, san-serif", fontSize: 16}}>Human Resource</h5>
          <div className="row">
            {HumanResources.map((item) => (
              <div className="col-sm-12 col-md-6 col-lg-4 my-3">
                <ProductCard
                isProduct
                  src={item.icon}
                  product={item.name}
                  url=""
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-5">
          <h5 style={{fontFamily: "Poppins, san-serif", fontSize: 16}}>Finance</h5>
          <div className="row">
            {finance.map((item) => (
              <div className="col-sm-12 col-md-6 col-lg-4 my-3">
                <ProductCard
                isProduct
                  src={item.icon}
                  product={item.name}
                  url=""
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-5">
          <h5 style={{fontFamily: "Poppins, san-serif", fontSize: 16}}>Inventory</h5>
          <div className="row">
            {inventory.map((item) => (
              <div className="col-sm-12 col-md-6 col-lg-4 my-3">
                <ProductCard
                isProduct
                  src={item.icon}
                  product={item.name}
                  url={item.path}
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-5">
          <h5 style={{fontFamily: "Poppins, san-serif", fontSize: 16}}>Transport</h5>
          <div className="row">
            {transport.map((item) => (
              <div className="col-sm-12 col-md-6 col-lg-4 my-3">
                <ProductCard
                isProduct
                  src={item.icon}
                  product={item.name}
                  url={item.path}
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-5">
          <h5 style={{fontFamily: "Poppins, san-serif", fontSize: 16}}>Sales</h5>
          <div className="row">
            {sales.map((item) => (
              <div className="col-sm-12 col-md-6 col-lg-4 my-3">
                <ProductCard
                isProduct
                  src={item.icon}
                  product={item.name}
                  url=""
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-5">
          <h5 style={{fontFamily: "Poppins, san-serif", fontSize: 16}}>Marketing</h5>
          <div className="row">     
              <div className="col-sm-12 col-md-6 col-lg-4 my-3">
                <ProductCard
                isProduct
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679659201/webbermill-website-assets/WSPIcons/SMS_zxwapg.svg"
                  product="SMS"
                  url=""
                  description="Easily SMS-enable your website, applications and customer relationship management platform with our fast and reliable solution at affordable prices."
                />
              </div>
           
          </div>
        </div>
        <div className="my-5">
          <h5 style={{fontFamily: "Poppins, san-serif", fontSize: 16}}>Productivity</h5>
          <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 my-3">
                <ProductCard
                isProduct
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679659203/webbermill-website-assets/WSPIcons/FileManager_stjhlz.svg"
                  product="File Manager"
                  url=""
                  description="Deploy modern solution that automatically integrate with all apps on our platform. Efficiently and securely manage, organize, and share their digital documents."
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 my-3">
                <ProductCard
                isProduct
                  src="https://res.cloudinary.com/webbermill/image/upload/v1679659199/webbermill-website-assets/WSPIcons/Sign_gd0bhj.svg"
                  product="Sign"
                  url=""
                  description="Easily impress your clients and keep your business moving forward from virtually anywhere, at any time."
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 my-3">
                <ProductCard
                isProduct
                  src="/assets/images/FrontDesk.svg"
                  product="Front Desk"
                  url="/frontdesk"
                  description="Revolutionize the way you welcome guests with Webber Mill Front Desk, the cutting-edge visitor management software."
                />
              </div>
          </div>
        </div>
        <br/>
        <br />
      </section>
    </>
  );
};

export default ProductsIndex;
